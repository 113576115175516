import { safeFetch } from '../utils';
import { IPagination, IPaginationResponse, TeamInfo } from '@gf/cross-platform-lib/interfaces';
import { GetCurrentApplicationConfiguration, Method } from '@gf/cross-platform-lib/utils';

const appConfig = GetCurrentApplicationConfiguration();

export const getAllTeams = async (organizationId: string, pagination: IPagination) => {
  const url = `${appConfig.api.team.getUrl!(Method.GET)}/search?organizationId=${organizationId}&page=${
    pagination.page
  }&size=${pagination.size}`;
  return await safeFetch<IPaginationResponse<TeamInfo>>(url!);
};

export const getTeam = async (teamId: string) => {
  const url = `${appConfig.api.team.getUrl!(Method.GET)}/${teamId}`;
  return await safeFetch<TeamInfo>(url);
};
