import { SchoolInterface, TeamInfo } from '@gf/cross-platform-lib/interfaces';
/**
 * @param array
 * @param compareFunc
 * @return: Returns a sorted array with returning new array.
 * @description: This is the same with Array.prototype.toSorted() method in ES2023 or lodash orderBy.
 */
export const sortedArray = (array: any[], compareFunc: (a: any, b: any) => number): any[] => {
  return [...(array || [])].sort(compareFunc);
};

export const sortSchoolsByIds = (schoolIds: string[], schools: SchoolInterface[]): SchoolInterface[] => {
  return schoolIds
    .reduce((result, schoolId) => {
      const school = schools.find(school => school.huddleId === schoolId);
      if (school) result.push(school);
      return result;
    }, [] as SchoolInterface[])
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const sortTeamsBySchlIds = (schoolIds: string[], teams: TeamInfo[]): TeamInfo[] => {
  return schoolIds
    .reduce((result, schoolId) => {
      const schoolTeams = teams.filter(team => team.organizationId === schoolId);
      result.push(...schoolTeams);
      return result;
    }, [] as TeamInfo[])
    .sort((a, b) => {
      let titleA = [a.gender, a.levelName, a.activityName].filter(Boolean).join(' ');
      let titleB = [b.gender, b.levelName, b.activityName].filter(Boolean).join(' ');

      return titleA.localeCompare(titleB);
    });
};
